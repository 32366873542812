import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Error } from "./pages/supports";
import {
  Avatars,
  Alerts,
  Buttons,
  Charts,
  Tables,
  Fields,
  Headings,
  Colors,
} from "./pages/blocks";
import { Ecommerce, ForgotPassword, Login } from "./pages/master";

import { useEffect, useState } from "react";

import UsersList from "./pages/master/UsersList";
import AddPlan from "./pages/master/Plans/AddPlan";
import PlanList from "./pages/master/Plans/PlanList";
import EditPlan from "./pages/master/Plans/EditPlan";
import AddService from "./pages/master/Link Store/AddService";
import ServicesList from "./pages/master/Link Store/ServicesList";
import EditService from "./pages/master/Link Store/EditService";
import AddCategory from "./pages/master/Product Categories/AddCategory";
import CategoryList from "./pages/master/Product Categories/CategoryList";
import AddProduct from "./pages/master/Products/AddProduct";
import ProductList from "./pages/master/Products/ProductList";
import EditProduct from "./pages/master/Products/EditProduct";
import AddCoupon from "./pages/master/Coupons/AddCoupon";
import CouponsList from "./pages/master/Coupons/CouponsList";
import EditCoupon from "./pages/master/Coupons/EditCoupon";
import TeamsList from "./pages/master/Teams/TeamsList";
import OrdersList from "./pages/master/Orders/OrdersList";
import AddsellerProductCatagory from "./pages/master/AddsellerProductCatagory";
import SellerProductCatagoryList from "./pages/master/SellerProductCatagoryList";
import Addsellersubcategories from "./pages/master/Addsellersubcategories";
import Sellersubcategorieslist from "./pages/master/Sellersubcategorieslist";
import Addsellerproductvariant from "./pages/master/Addsellerproductvariant";
import Sellerproductvariantlist from "./pages/master/Sellerproductvariantlist";
import Addproductattributes from "./pages/master/Addproductattributes";
import Productattributeslist from "./pages/master/Productattributeslist";
import { GetIdProvider } from "./context/GetId";
import VarientValue from "./pages/master/VarientValue";
import VarientValueList from "./pages/master/VarientValueList";
import AttributeValueAction from "./pages/master/AttributeValueAction";
import AttributeValueList from "./pages/master/AttributeValueList";
import AddSizeChart from "./pages/master/Sizechart/AddSizeChart";
import SizeChartList from "./pages/master/Sizechart/SizeChartList";
import EditSizeChart from "./pages/master/Sizechart/EditSizeChart";

export default function App() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);
  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    }
  }, [token]);

  return (
    <GetIdProvider>
      <ThemeProvider>
        <LoaderProvider>
          <Routes>
            {/* master Pages */}
            {/* <Route path="/ecommerce" element={<Ecommerce /> } /> */}
            {/* <Route path="/analytics" element={<Analytics /> } />
                        <Route path="/crm" element={<CRM /> } />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/user-list" element={<UserList />} />
                        <Route path="/user-profile" element={<UserProfile />} />
                        <Route path="/my-account" element={<MyAccount />} />
                        <Route path="/product-list" element={<ProductList />} />
                        <Route path="/product-view" element={<ProductView />} />
                        <Route path="/product-upload" element={<ProductUpload />} />
                        <Route path="/invoice-list" element={<InvoiceList />} />
                        <Route path="/invoice-details" element={<InvoiceDetails />} />
                        <Route path="/order-list" element={<OrderList />} />
                        <Route path="/message" element={<Message />} />
                        <Route path="/notification" element={<Notification />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/blank-page" element={<BlankPage />} /> */}

            <Route path="/login" element={<Login />} />

            {/* Blocks Pages */}
            <Route path="/headings" element={<Headings />} />
            <Route path="/buttons" element={<Buttons />} />
            <Route path="/avatars" element={<Avatars />} />
            <Route path="/colors" element={<Colors />} />
            <Route path="/charts" element={<Charts />} />
            <Route path="/tables" element={<Tables />} />
            <Route path="/fields" element={<Fields />} />
            <Route path="/alerts" element={<Alerts />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            {isLoggedIn ? (
              <>
                <Route path="/" element={<Ecommerce />} />
                <Route path="/users-list" element={<UsersList />} />

                <Route path="/add-plan" element={<AddPlan />} />
                <Route path="/edit-plan" element={<EditPlan />} />
                <Route path="/plan-list" element={<PlanList />} />

                <Route path="/add-service" element={<AddService />} />
                <Route path="/edit-service" element={<EditService />} />
                <Route path="/link-store" element={<ServicesList />} />

                <Route path="/add-category" element={<AddCategory />} />
                <Route path="/category-list" element={<CategoryList />} />

                <Route path="/add-product" element={<AddProduct />} />
                <Route path="/edit-product" element={<EditProduct />} />
                <Route path="/product-list" element={<ProductList />} />

                <Route path="/add-coupon" element={<AddCoupon />} />
                <Route path="/edit-coupon" element={<EditCoupon />} />
                <Route path="/coupons-list" element={<CouponsList />} />

                <Route path="/order-list" element={<OrdersList />} />

                <Route path="/teams-list" element={<TeamsList />} />

                {/* sudarsan End */}
                <Route
                  path="/addsellerProductcatagory"
                  element={<AddsellerProductCatagory />}
                />
                <Route
                  path="/sellerProductcatagorylist"
                  element={<SellerProductCatagoryList />}
                />

                <Route
                  path="/addsellersubcategories"
                  element={<Addsellersubcategories />}
                />
                <Route
                  path="/edit-seller-subcategory/:id"
                  element={<Addsellersubcategories />}
                />
                <Route
                  path="/sellersubcategorieslist"
                  element={<Sellersubcategorieslist />}
                />

                <Route
                  path="/addsellerproductvariant"
                  element={<Addsellerproductvariant />}
                />
                <Route
                  path="/sellerproductvariantlist"
                  element={<Sellerproductvariantlist />}
                />
                <Route
                  path="/addsellerproductvariantvalues"
                  element={<VarientValue />}
                />
                <Route
                  path="/editsellerproductvariantvalues/:id"
                  element={<VarientValue />}
                />
                <Route
                  path="/sellerproductvariantvalueslist"
                  element={<VarientValueList />}
                />

                <Route
                  path="/addproductattributes"
                  element={<Addproductattributes />}
                />
                <Route
                  path="/productattributeslist"
                  element={<Productattributeslist />}
                />

                <Route
                  path="/addproductattributesvalue"
                  element={<AttributeValueAction />}
                />
                <Route
                  path="/editproductattributesvalue/:id"
                  element={<AttributeValueAction />}
                />
                <Route
                  path="/productattributesvalueslist"
                  element={<AttributeValueList />}
                />

                <Route path="/add-size-chart" element={<AddSizeChart />} />
                <Route path="/edit-size-chart/:chartId" element={<EditSizeChart />} />
                <Route path="/size-chart-list" element={<SizeChartList />} />

                {/* <Route path="/teachers-list" element={<TeachersList />} />

                        <Route path="/add-board" element={<AddBoard />} />
                        <Route path="/boards-list" element={<BoardsList />} />
           
                        <Route path="/add-school" element={<AddSchool />} />
                        <Route path="/schools-list" element={<SchoolsList />} />

                        <Route path="/add-grade" element={<AddGrade />} />
                        <Route path="/grades-list" element={<GradesList />} />

                        <Route path="/add-goal" element={<AddGoal />} />
                        <Route path="/goals-list" element={<GoalsList />} />
                        
                        <Route path="/add-subject" element={<AddSubject />} />
                        <Route path="/edit-subject" element={<EditSubject />} />
                        <Route path="/subjects-list" element={<SubjectsList />} />

                        <Route path="/add-chapter" element={<AddChapter />} />
                        <Route path="/edit-chapter" element={<EditChapter />} />
                        <Route path="/chapters-list" element={<ChaptersList />} />

                        <Route path="/add-video" element={<AddVideo />} />
                        <Route path="/videos-list" element={<VideosList />} />

                        <Route path="/add-pincode" element={<AddPincode />} />
                        <Route path="/pincodes-list" element={<PincodesList />} />

                        <Route path="/add-test" element={<AddTest />} />
                        <Route path="/tests-list" element={<TestsList />} />

                        <Route path="/videos-list" element={<VideosList />} /> */}
              </>
            ) : (
              <Route path="*" element={<Error />} />
            )}

            {/* Supports Pages */}
          </Routes>
        </LoaderProvider>
      </ThemeProvider>
    </GetIdProvider>
  );
}
