import React, { useContext, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Form } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { Breadcrumb } from "../../components";
import { Box, Button } from "../../components/elements";
import { Link, useNavigate } from "react-router-dom";
import PageLayout from "../../layouts/PageLayout";
import HTTPService from "../../utils/axios";
import { GetIdContext } from "../../context/GetId";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";

function VarientValueList() {
  const { invokeApi } = HTTPService();
  const navigate = useNavigate();

  const [subCatList, setSubCatList] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  async function fetchVariantList() {
    // const token = localStorage.getItem("token")
    await invokeApi({
      method: "GET",
      url: "admin/get-all-variant-values",
    })
      .then((data) => {
        setSubCatList(data.data?.variantValues);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    fetchVariantList();
  }, []);

  async function changeStatus(id) {
    await invokeApi({
      method: "PUT",
      url: `admin/edit-variant-value/${id}`,
      data: { active: Boolean(selectedCategory.status) },
    })
      .then((res) => {
        setOpenWarning(false);
        fetchVariantList();
        alert("Subcategory status changed successfully");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function handleDeleteCategory(id) {
    await invokeApi({
      method: "DELETE",
      url: `admin/delete-variant-value/${id}`,
    })
      .then((res) => {
        setOpenWarning(false);
        fetchVariantList();
        alert("deleted successfully");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const plans_data = {
    columns: [
      {
        label: "SL No",
        field: "sl_no",
        sort: "asc",
        width: 50,
      },

      {
        label: "Value Name ",
        field: "value_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Variant Name",
        field: "variant_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: subCatList?.map((value, index) => {
      return {
        sl_no: index + 1,
        value_name: value.title,
        variant_name: value.variant?.title,
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <div class="form-check form-switch me-4">
              <input
                checked={Boolean(value.active)}
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                onClick={() => {
                  setOpenWarning(true);
                  setSelectedCategory({
                    id: value._id,
                    status: !value.active,
                    title: value.title,
                  });
                  setWarning({
                    text: `Do you want to change the status to ${
                      value.active === false ? `Active?` : `Inactive?`
                    }`,
                    type: "status",
                  });
                }}
              />
            </div>

            <button
              onClick={() => {
                // setItemDetails(subcat.title);
                navigate(`/editsellerproductvariantvalues/${value._id}`);
              }}
              type="button"
              style={{
                width: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className="btn btn-success"
            >
              <i className="fas fa-edit"></i>
            </button>

            <button
              type="button"
              className="btn btn-danger"
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setOpenWarning(true);
                setSelectedCategory({
                  id: value._id,
                });
                setWarning({
                  text: `Do you want to delete the value? `,
                  type: "delete",
                });
              }}
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };
  return (
    <>
      <>
        <PageLayout>
          <Row>
            <Col xl={12}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Breadcrumb title={"Product Variant List"}></Breadcrumb>
                <Link to={`/addsellerproductvariantvalues`}>
                  <Button className="btn btn-primary">Add Variant Value</Button>
                </Link>
              </div>
            </Col>
            <Col xl={12}>
              <Box className="mc-card">
                <Row xs={1} md={1} xl={1}>
                  <Col xl={12}>
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <MDBDataTable
                            striped
                            bordered
                            data={plans_data}
                            small
                            style={{ marginTop: "1rem" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Row>

          {/* Warning Category Modal */}
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={openWarning}
            onClose={() => setOpenWarning(false)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "1000",
            }}
          >
            <Sheet
              variant="outlined"
              sx={{
                width: "30%",
                borderRadius: "md",
                p: 3,
                boxShadow: "lg",
                bgcolor: "#fff",
              }}
            >
              <ModalClose
                variant="outlined"
                sx={{
                  top: "calc(-1/4 * var(--IconButton-size))",
                  right: "calc(-1/4 * var(--IconButton-size))",
                  boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                  borderRadius: "50%",
                  bgcolor: "#eee",
                }}
              />
              <Typography
                id="basic-modal-dialog-title"
                component="h2"
                level="inherit"
                fontSize="1.25em"
                mb={5}
              >
                {warning.text}
              </Typography>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                }}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (warning.type === "status") {
                      changeStatus(selectedCategory.id);
                      setOpenWarning(false);
                    } else if (warning.type === "delete") {
                      handleDeleteCategory(selectedCategory.id);
                      setOpenWarning(false);
                    }
                  }}
                  type="button"
                  style={{
                    width: "8rem",
                    fontSize: "0.8rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "0.5rem",
                  }}
                  className="btn btn-success"
                >
                  Yes
                </button>
                <button
                  onClick={() => setOpenWarning(false)}
                  type="button"
                  style={{
                    width: "8rem",
                    fontSize: "0.8rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "0.5rem",
                  }}
                  className="btn btn-danger"
                >
                  No
                </button>
              </div>
            </Sheet>
          </Modal>
        </PageLayout>
      </>
    </>
  );
}

export default VarientValueList;
