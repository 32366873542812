import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Form } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { Breadcrumb } from "../../components";
import { Box, Button } from "../../components/elements";
import { Link } from "react-router-dom";
import PageLayout from "../../layouts/PageLayout";
import HTTPService from "../../utils/axios";
import { DealsTable } from "../../components/tables";
import CustomTable from "../../components/tables/customtable/CustomTable";
import { Td, Tr } from "../../components/elements/Table";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
import { TextField } from "@mui/material";

function SellerProductCatagoryList() {
  const { invokeApi } = HTTPService();
  // const token = localStorage.getItem("token")

  const [allCategoryList, setAllCategoryList] = useState();
  const [openEditCategory, setOpenEditCategory] = useState(false);

  const [editedCategory, setEditedCategory] = useState({
    id: "",
    title: "",
    description: "",
  });

  const [selectedCategory, setSelectedCategory] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const editCategory = async () => {
    // Validation checks
    if (!editedCategory.title) {
      alert("Please provide Title !");
      return;
    }

    // Upload the category data

    await invokeApi({
      method: "PUT",
      url: `admin/edit-seller-category/${editedCategory.id}`,
      data: { title: editedCategory.title },
    })
      .then((res) => {
        alert("Category Edited Successfully!");
        setOpenEditCategory(false);
        fetchAllCategory();
      })
      .catch((error) => {
        console.error("Error editing category:", error);
        alert("Error editing category. Please try again.");
      });
  };
  const changeStatus = async (categoryId) => {
    await invokeApi({
      method: "PUT",
      url: `admin/edit-seller-category/${selectedCategory.id}`,
      data: {
        active: Boolean(selectedCategory.status),
        title: selectedCategory.title,
      },
    })
      .then((data) => {
        alert("Status Changed Successfully");
        fetchAllCategory();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const fetchAllCategory = async () => {
    await invokeApi({
      method: "GET",
      url: "admin/get-all-seller-categories",
      // headers:{token:token}
    })
      .then((data) => {
        setAllCategoryList(data.data.categories);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteCategory = (id) => {
    invokeApi({
      method: "DELETE",
      url: `admin/delete-seller-category/${id}`,
    })
      .then((res) => {
        fetchAllCategory();
        alert("product deleted successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAllCategory();
  }, []);

 

  const plans_data = {
    columns: [
      {
        label: "SL No",
        field: "sl_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Catagory Name",
        field: "seller_cat_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: allCategoryList?.map((category, index) => {
      return {
        sl_no: <td>{index + 1}</td>,
        seller_cat_name: <td>{category.title}</td>,
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <div class="form-check form-switch me-4">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                checked={category.active}
                id="flexSwitchCheckDefault"
                onClick={() => {
                  setOpenWarning(true);
                  setSelectedCategory({
                    id: category._id,
                    status: !category.active,
                    title: category.title,
                  });
                  setWarning({
                    text: `Do you want to change the status to ${
                      category.active === false ? `Active?` : `Inactive?`
                    }`,
                    type: "status",
                  });
                }}
              />
            </div>
            <button
              type="button"
              onClick={() => {
                setSelectedCategory({ title: category.title });
                setOpenEditCategory(true);
                setEditedCategory({
                  title: category.title,
                  id: category._id,
                });
              }}
              style={{
                width: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className="btn btn-success"
            >
              <i className="fas fa-edit"></i>
            </button>
            <button
              onClick={() => handleDeleteCategory(category._id)}
              type="button"
              className="btn btn-danger"
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };
  return (
    <>
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Breadcrumb title={"Seller Product Catagory List"}></Breadcrumb>
              <Link to={`/addsellerProductcatagory`}>
                <Button className="btn btn-primary">Add Seller Catagory</Button>
              </Link>
            </div>
          </Col>
          <Col xl={12}>
            <Box className="mc-card">
              <Row xs={1} md={1} xl={1}>
                <Col xl={12}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          data={plans_data}
                          small
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>

        {/* Warning Category Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(selectedCategory.id);
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    handleDeleteCategory(selectedCategory.id);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>

        {/* Edit Category Modal */}
        <Modal
          aria-labelledby="modal-title-edit"
          aria-describedby="modal-desc-edit"
          open={openEditCategory}
          onClose={() => setOpenEditCategory(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "50%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              Edit {selectedCategory.title} Category
            </Typography>

            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <TextField
                id="outlined-required"
                defaultValue=""
                placeholder="Enter Category Title ..."
                multiline
                label="Category Title"
                value={editedCategory.title}
                onChange={(e) =>
                  setEditedCategory({
                    ...editedCategory,
                    title: e.target.value,
                  })
                }
                sx={{ width: "100%" }}
              />
            </Stack>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <button
                disabled={editedCategory ? false : true}
                onClick={editCategory}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </Sheet>
        </Modal>
      </PageLayout>
    </>
  );
}

export default SellerProductCatagoryList;
