import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { Button } from "../../components/elements";
import { TextField } from "@mui/material";
import PageLayout from "../../layouts/PageLayout";
import { Breadcrumb } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "../../components/elements";
import HTTPService from "../../utils/axios";

function Addproductattributes() {
  const { invokeApi } = HTTPService();
  const navigate = useNavigate();
  const [attribute, setAttribute] = useState({});
  async function addAttribute() {
    await invokeApi({
      method: "POST",
      url: `admin/add-seller-attribute`,
      data: attribute,
    })
      .then((res) => {
        alert(res.message);
        navigate("/productattributeslist");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      <PageLayout>
        <Row xs={1} md={1} xl={1}>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Breadcrumb title={"Product Attributes "}></Breadcrumb>
              <Link to={`/productattributeslist`}>
                <Button className="btn btn-primary">
                  Product Attribute List
                </Button>
              </Link>
            </div>
          </Col>
          <Box className="mc-card">
            <Col xl={6}>
              <CardHeader title="Attribute Name" />
              <TextField
                id="outlined-required"
                placeholder="Enter Attribute Name"
                sx={{ width: "100%" }}
                onChange={(e) => setAttribute({ title: e.target.value })}
              />
            </Col>

            <Col xl={12} className="mt-4">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Button className="btn btn-primary" onClick={addAttribute}>
                  {" "}
                  Add Attribute{" "}
                </Button>
              </div>
            </Col>
          </Box>
        </Row>
      </PageLayout>
    </>
  );
}

export default Addproductattributes;
