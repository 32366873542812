import { createContext, useState } from "react";

export const GetIdContext = createContext();

export const GetIdProvider = ({ children }) => {
   const [itemDetails,setItemDetails]=useState("");
    return (
        <GetIdContext.Provider value={{ itemDetails,setItemDetails }}>
            { children }
        </GetIdContext.Provider>
    )
}