import React, { useEffect, useState } from "react";
import PageLayout from "../../../layouts/PageLayout";
import { Col, Row } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Option, Select } from "../../../components/elements";
import { CardHeader } from "../../../components/cards";
import { TextField } from "@mui/material";
import HTTPService from "../../../utils/axios";

function EditSizeChart() {
  const navigate = useNavigate();
  const { chartId } = useParams();
  const { invokeApi } = HTTPService();
  const [form, setForm] = useState({});
  const [inchFile, setInchFile] = useState();
  const [cmFile, setCmFile] = useState();
  const [imgarr, setimgArr] = useState([]);

  const [inchUrl, setInchUrl] = useState("");
  const [cmUrl, setCmUrl] = useState("");
  const token = localStorage.getItem("token");

  function uploadInchImg() {
    console.log("inchfile", inchFile);
    if (inchFile) {
      const formData = new FormData(); // create FormData object
      formData.append("file", inchFile); // append file to formData
      invokeApi({
        method: "POST",
        url: "upload",
        data: formData, // pass formData
        headers: {
          "Content-Type": "multipart/form-data", // set correct content type
        },
      }).then((res) => {
        if (res.success === true) {
          setInchUrl(res.fileUrl);
        }
      });
    }
  }

  function uploadCmImg() {
    console.log("cmfile", cmFile);
    if (cmFile) {
      const formData = new FormData(); // create FormData object
      formData.append("file", cmFile); // append file to formData
      invokeApi({
        method: "POST",
        url: "upload",
        data: formData, // pass formData
        headers: {
          "Content-Type": "multipart/form-data", // set correct content type
        },
      }).then((res) => {
        if (res.success === true) {
          setCmUrl(res.fileUrl);
        }
      });
    }
  }

  const handleImgArray = () => {
    if (inchUrl !== "") {
      setimgArr([...imgarr, { url: inchUrl, metrics: "in" }]);
    }
    if (cmUrl !== "") {
      setimgArr([...imgarr, { url: cmUrl, metrics: "cm" }]);
    }
  };

  useEffect(() => {
    handleImgArray();
  }, [inchUrl, cmUrl]);

  useEffect(() => {
    if (inchUrl !== "") {
      document.querySelector("#inchInput").setAttribute("disabled", "true");
      document.querySelector("#inchupload").setAttribute("disabled", "true");
    }
    if (cmUrl !== "") {
      document.querySelector("#cmInput").setAttribute("disabled", "true");
      document.querySelector("#cmupload").setAttribute("disabled", "true");
    }
  }, [inchUrl, cmUrl]);

  useEffect(() => {
    if (!inchFile) {
      document.querySelector("#inchupload").setAttribute("disabled", "true");
    } else {
      document.querySelector("#inchupload").removeAttribute("disabled");
    }
    if (!cmFile) {
      document.querySelector("#cmupload").setAttribute("disabled", "true");
    } else {
      document.querySelector("#cmupload").removeAttribute("disabled");
    }
  }, [inchFile, cmFile]);
  const setSizeChart = async () => {
    if (inchUrl !== "" || cmUrl !== "") {
      await invokeApi({
        method: "PUT",
        url: `admin/edit-size-chart/${chartId}`,
        data: { images: imgarr },
        headers: { token: token },
      })
        .then((res) => {
          alert("successfull");
          navigate("/size-chart-list");
        })
        .catch((e) => console.log(e));
    } else {
      alert("Please upload atleast  one image");
    }
  };
  useEffect(() => {
    console.log("form", form);
  }, [form]);
  return (
    <PageLayout>
      <Row xs={1} md={1} xl={1}>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Edit Size Chart "}></Breadcrumb>
            <Link to={`/size-chart-list`}>
              <Button className="btn btn-primary">Size Chart List</Button>
            </Link>
          </div>
        </Col>
        <Box className="mc-card ">
          <div className="row w-100">
            {/* <Col xl={3}>
              <div>
                <CardHeader title="Select Gender" />
                <Select
                  //   value={editpath ? form.category && form.category : form.category}
                  value={form.gender}
                  className="form-select"
                  onChange={(e) => setForm({ ...form, gender: e.target.value })}
                >
                  <Option>Select Gender</Option>
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                </Select>
              </div>
            </Col>
            <Col xl={3}>
              <div>
                <CardHeader title="Category" />
                <Select
                  //   value={editpath ? form.category && form.category : form.category}
                  value={form.category}
                  className="form-select"
                  onChange={(e) =>
                    setForm({ ...form, category: e.target.value })
                  }
                >
                  <Option>Select Category</Option>
                  <Option value="topware">Topware</Option>
                  <Option value="bottomware">Bottomware</Option>
                  <Option value="footware">Footware</Option>
                </Select>
              </div>
            </Col> */}
            <Col xl={3}>
              <div>
                <CardHeader title="Chart In Inch" />
                <TextField
                  id="inchInput"
                  type="file"
                  placeholder="Enter Size in Inch"
                  onChange={(e) => setInchFile(e.target.files[0])}
                />
                <button
                  id="inchupload"
                  className="btn btn-primary"
                  onClick={uploadInchImg}
                >
                  Upload
                </button>
              </div>
            </Col>
            <Col xl={3}>
              <div>
                <CardHeader title="Chart In CM" />
                <TextField
                  id="cmInput"
                  type="file"
                  placeholder="Enter Size in CM"
                  onChange={(e) => setCmFile(e.target.files[0])}
                />
                <button
                  id="cmupload"
                  className="btn btn-primary"
                  onClick={uploadCmImg}
                >
                  Upload
                </button>
              </div>
            </Col>
          </div>

          <Col xl={12} className="mt-4">
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Button onClick={setSizeChart} className="btn btn-primary">
                {" "}
                Update Size Chart{" "}
              </Button>
            </div>
          </Col>
        </Box>
      </Row>
    </PageLayout>
  );
}

export default EditSizeChart;
