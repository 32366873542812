import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { Button } from "../../components/elements";
import { TextField } from "@mui/material";
import PageLayout from "../../layouts/PageLayout";
import { Breadcrumb } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "../../components/elements";
import HTTPService from "../../utils/axios";

function Addsellerproductvariant() {
    const navigate = useNavigate();
  const [form, setForm] = useState({});
  const { invokeApi } = HTTPService();
  async function handleAdd() {
    await invokeApi({
      method: "POST",
      url: "admin/add-seller-variant",
      data: form,
    }).then((res)=>{
        alert(res.message)
        navigate("/sellerproductvariantlist")
    }).catch((err)=>{
        console.error(err)
    })
  }

  return (
    <>
      <PageLayout>
        <Row xs={1} md={1} xl={1}>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Breadcrumb title={"Product Variant  "}></Breadcrumb>
              <Link to={`/sellerproductvariantlist`}>
                <Button className="btn btn-primary">
                  Product Variant List
                </Button>
              </Link>
            </div>
          </Col>
          <Box className="mc-card">
            <Col xl={6}>
              <CardHeader title="Variant Name" />
              <TextField
                id="outlined-required"
                placeholder="Enter Variant Name"
                sx={{ width: "100%" }}
                onChange={(e) => setForm({ title: e.target.value })}
              />
            </Col>

            <Col xl={12} className="mt-4">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Button onClick={handleAdd} className="btn btn-primary">
                  {" "}
                  Add Variant{" "}
                </Button>
              </div>
            </Col>
          </Box>
        </Row>
      </PageLayout>
    </>
  );
}

export default Addsellerproductvariant;
