import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { Button } from "../../components/elements";
import { TextField } from "@mui/material";
import PageLayout from "../../layouts/PageLayout";
import { Breadcrumb } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "../../components/elements";
import axios from "axios";
import HTTPService from "../../utils/axios";


export default function AddsellerProductCatagory() {

  const navigate = useNavigate();
  const [sellercategory, setSellercategory] = useState({
    title: "",
  });
 

  const AddsellerCategory = async () => {
    try {
      // Validation checks
      if (!sellercategory.title) {
        alert("Please provide Category Title !");
        return;
      } else {
        // Upload the Seller category data
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}admin/add-seller-category`,
          sellercategory,
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );

        alert("Seller Category Added Successfully!");
        navigate("/sellerProductcatagorylist");
      }
    } catch (error) {
      console.error("Error adding Seller category:", error);
      alert("Error adding Seller category. Please try again.");
    }
  };

  return (
    <>
      <PageLayout>
        <Row xs={1} md={1} xl={1}>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Breadcrumb title={"Seller Catagory "}></Breadcrumb>
              <Link to={`/sellerProductcatagorylist`}>
                <Button className="btn btn-primary">
                  Seller Catagory List
                </Button>
              </Link>
            </div>
          </Col>
          <Box className="mc-card">
            <Col xl={6}>
              <CardHeader title="Category Name" />
              <TextField
                id="outlined-required"
                placeholder="Enter Category Name"
                sx={{ width: "100%" }}
                value={sellercategory.title}
                onChange={(e) => setSellercategory({ title: e.target.value })}
              />
            </Col>

            <Col xl={12} className="mt-4">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Button className="btn btn-primary" onClick={AddsellerCategory}>
                  {" "}
                  Add Category{" "}
                </Button>
              </div>
            </Col>
          </Box>
        </Row>
      </PageLayout>
    </>
  );
}
