import React, { useContext, useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { Button, Col, Row } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CardHeader } from "../../components/cards";
import { TextField } from "@mui/material";
import { Box, Option, Select } from "../../components/elements";
import HTTPService from "../../utils/axios";

const VarientValue = () => {
  const { id } = useParams();
  const { invokeApi } = HTTPService();
  const [varient, setVarient] = useState([]);
  const [allValues, setAllValues] = useState([]);
  const location = useLocation();
  const [form, setForm] = useState({});
  const navigate = useNavigate();
  const editpath = location.pathname.includes(
    "/editsellerproductvariantvalues"
  );

  function fetchEditValue() {
    invokeApi({
      method: "GET",
      url: "admin/get-all-variant-values",
    }).then((res) => {
      setAllValues(res.data?.variantValues);
    });
  }
  useEffect(() => {
    if (id) {
      fetchEditValue();
    }
  }, []);

  useEffect(() => {
    if (allValues !== undefined) {
      const mainValue = allValues.find((item) => item._id === id);
      setForm({ title: mainValue?.title, variantId: mainValue?.variant?._id });
    } else {
      return;
    }
  }, [allValues]);

  async function fetchVariant() {
    await invokeApi({
      method: "GET",
      url: "/admin/get-all-seller-variants",
    })
      .then((res) => {
        setVarient(res.variants);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchVariant();
  }, []);
  function addVarientValue() {
    if (editpath) {
      invokeApi({
        method: "PUT",
        url: `admin/edit-variant-value/${id}`,
        data: { title: form.title },
      })
        .then((res) => {
          alert(res.message);
          navigate("/sellerproductvariantvalueslist");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      invokeApi({
        method: "POST",
        url: "/admin/add-variant-value",
        data: form,
      })
        .then((res) => {
          alert(res.message);
          navigate("/sellerproductvariantvalueslist");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  useEffect(() => {
    if (location.pathname === "/addsellerproductvariantvalues") {
      setForm({ title: "", variantId: "" });
    }
  }, [location]);
  return (
    <>
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Breadcrumb
                title={`${editpath ? "Edit" : "Add"} Variant Value`}
              ></Breadcrumb>
              <Link to={`/sellerproductvariantvalueslist`}>
                <Button className="btn btn-primary">
                  Seller Variant Value List
                </Button>
              </Link>
            </div>
          </Col>
          <Box className="mc-card">
            <Col xl={12}>
              <Row xs={1} md={1} xl={1}>
                <Col xl={6}>
                  <CardHeader title="Select Variant" />
                  <Select
                    value={
                      editpath
                        ? form.variantId && form.variantId
                        : form.variantId
                    }
                    className="form-select"
                    onChange={(e) =>
                      setForm({ ...form, variantId: e.target.value })
                    }
                  >
                    <Option>Select Variant</Option>
                    {varient &&
                      varient.map((item, index) => (
                        <Option key={index} value={item._id}>
                          {item.title}
                        </Option>
                      ))}
                  </Select>
                </Col>

                <Col xl={6}>
                  <CardHeader title="Variant Value" />
                  <TextField
                    value={editpath ? form.title && form.title : form.title}
                    onChange={(e) =>
                      setForm({ ...form, title: e.target.value })
                    }
                    id="outlined-multiline"
                    placeholder="Enter Variant Value"
                    sx={{ width: "100%" }}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl={12} className="mt-4">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Button onClick={addVarientValue} className="btn btn-primary">
                  {editpath ? "Edit" : "Add"} Variant Value
                </Button>
              </div>
            </Col>
          </Box>
        </Row>
      </PageLayout>
    </>
  );
};

export default VarientValue;
