import React, { useContext, useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { Button, Col, Row } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CardHeader } from "../../components/cards";
import { TextField } from "@mui/material";
import { Box, Option, Select } from "../../components/elements";
import HTTPService from "../../utils/axios";

function Addsellersubcategories() {
  const { id } = useParams();
  const location = useLocation();

  const editpath = location.pathname.includes("/edit-seller-subcategory");
  const { invokeApi } = HTTPService();
  const navigate = useNavigate();
  const [allSubcat, setAllSubcat] = useState();
  const [form, setForm] = useState({});
  const [catList, setCatList] = useState();

  async function fetchEditSubcat() {
    await invokeApi({
      method: "GET",
      url: `admin/get-all-subcategories`,
    })
      .then((data) => {
        setAllSubcat(data.subcategories?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (id) {
      fetchEditSubcat();
    }
  }, [id]);

  useEffect(() => {
    if (allSubcat !== undefined) {
      const subcat = allSubcat.find((item) => item._id === id);
      setForm({ title: subcat.title, category: subcat?.category?._id });
    } else {
      return;
    }
  }, [allSubcat]);

  const fetchAllCategory = async () => {
    await invokeApi({
      method: "GET",
      url: "admin/get-all-seller-categories",
    })
      .then((data) => {
        setCatList(data.data.categories);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchAllCategory();
  }, []);

  const addSubCategory = async () => {
    if (editpath) {
      await invokeApi({
        method: "PUT",
        url: `admin/edit-seller-subcategory/${id}`,
        data: form,
      })
        .then((res) => {
          setForm({});
          navigate("/sellersubcategorieslist");
          alert("Subcategory edited successfully");
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      await invokeApi({
        method: "POST",
        url: "admin/add-seller-subcategory",
        data: form,
      })
        .then((res) => {
          setForm({});
          navigate("/sellersubcategorieslist");
          alert("Subcategory added successfully");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <>
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Breadcrumb
                title={`${editpath ? "Edit" : "Add"} Seller Sub-Category`}
              ></Breadcrumb>
              <Link to={`/sellersubcategorieslist`}>
                <Button className="btn btn-primary">
                  Seller Sub-Category List
                </Button>
              </Link>
            </div>
          </Col>
          <Box className="mc-card">
            <Col xl={12}>
              <Row xs={1} md={1} xl={1}>
                <Col xl={6}>
                  <CardHeader title="Select Category" />
                  <Select
                    value={
                      editpath ? form.category && form.category : form.category
                    }
                    className="form-select"
                    onChange={(e) =>
                      setForm({ ...form, category: e.target.value })
                    }
                  >
                    <Option>Select Catagory</Option>
                    {catList &&
                      catList.map((item, index) => (
                        <Option key={index} value={item._id}>
                          {item.title}
                        </Option>
                      ))}
                  </Select>
                </Col>

                <Col xl={6}>
                  <CardHeader title="Sub Category" />
                  <TextField
                    value={editpath ? form.title && form.title : form.title}
                    onChange={(e) =>
                      setForm({ ...form, title: e.target.value })
                    }
                    id="outlined-multiline"
                    placeholder="Enter Sub Category"
                    sx={{ width: "100%" }}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl={12} className="mt-4">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Button onClick={addSubCategory} className="btn btn-primary">
                  {editpath ? "Edit" : "Add"} Seller Sub-Category
                </Button>
              </div>
            </Col>
          </Box>
        </Row>
      </PageLayout>
    </>
  );
}

export default Addsellersubcategories;
