import axios from "axios";

const axiosbaseUrl = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: axiosbaseUrl,
  timeout: 60000,
  withCredentials: false,
});

// Add a request interceptor to include the token in the headers
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.token = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const HTTPService = () => {
  const invokeApi = async (request) => {
    if (!instance) {
      console.error("Axios instance not initialized");
      return;
    }
    try {
      const config = {
        method: request.method,
        url: request.url,
        maxBodyLength: Infinity,
        data: request.data,
        params: request.params,
        headers: request.headers || {
          "Content-Type": "application/json",
          // "Content-Type": "multipart/form-data",
        },
        responseType: request.responseType,
      };
      const response = await instance.request(config);
      return response.data;
    } catch (error) {
      throw error.response || error;
    }
  };

  const invoke = (method, url, data, params, headers, responseType) => {
    return invokeApi({
      method,
      url,
      data,
      params,
      headers,
      responseType,
    });
  };

  return { invokeApi, invoke };
};

export default HTTPService;
